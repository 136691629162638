import template from './ModalComponent.html';
import { initializeHtmlElement } from './HTMLElementExtensions';

class ModalComponent extends HTMLElement {
    constructor() {
        super();

        initializeHtmlElement(this, template);

        this._modalOverlay = this.shadowRoot.querySelector('.modal-with-overlay');
        this._modalContainer = this.shadowRoot.querySelector(".modal-container");
        this._titleElement = this.shadowRoot.getElementById('modal-title-h3');
        this._modalContent = this.shadowRoot.getElementById('modal-content');
        
        this.mouseDownEvent = this.mouseDownEvent.bind(this);
        this.keyUpEvent = this.keyUpEvent.bind(this);
    }
    static get observedAttributes() {
        return ["title"];
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue === newValue)
            return;

        if (name === "title") {
            this._updateTitle(newValue);
        }
    }

    connectedCallback() {
        this._modalOverlay.addEventListener('mousedown', this.mouseDownEvent);
        document.addEventListener('keyup', this.keyUpEvent);
    }

    disconnectedCallback() {
        this._modalOverlay.removeEventListener('mousedown', this.mouseDownEvent);
        document.removeEventListener('keyup', this.keyUpEvent);
    }

    openModal(title, force, customContent) {
        this._openModal({ detail: { title, force, customContent } });
    }

    closeModal() {
        this._closeModal();
    }

    _updateTitle(title) {
        this._titleElement.textContent = title;
    }

    mouseDownEvent(e) {
        if (this._modalOverlay.getAttribute('force') === "false" && e.target.classList.contains('modal-with-overlay')) {
            this._closeModal();
            this.dispatchEvent(new CustomEvent('modalClosed'));
        }
    }

    keyUpEvent(e) {
        if (this._modalOverlay.getAttribute("force") === "false" && e.keyCode === 27) {
            this._closeModal();
            this.dispatchEvent(new CustomEvent('modalClosed'));
        }
    }

    _openModal(e) {
        this._modalOverlay.classList.remove('hidden');
        this._modalOverlay.setAttribute('force', e.detail.force);
        if (e.detail.title)
            this._updateTitle(e.detail.title);
        if (e.detail.customContent)
            this._modalContainer.classList.add('hidden');
        this._modalContent.scrollTo(0, 0);
    }

    _closeModal() {
        this._modalOverlay.classList.add('hidden');
        this._modalContainer.classList.remove('hidden');
        this._titleElement.textContent = '';
    }
}

customElements.define('modal-component', ModalComponent);