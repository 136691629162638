import { initializeHtmlElement } from './HTMLElementExtensions';
import template from './Tooltip.html';
import fontawesome from '@fortawesome/fontawesome-free/css/all.css'

class Tooltip extends HTMLElement {
    constructor() {
        super();
        initializeHtmlElement(this, template, [fontawesome]);
        this._tooltip = this.shadowRoot.getElementById('tooltip');
    }

    set hidden(value) {
        this._tooltip.toggleAttribute('hidden', value);
    }

    set title(value) {
        this._tooltip.title = value;
        $(this._tooltip).tooltip();
    }
}
customElements.define('custom-tooltip', Tooltip);
